.mainHomeContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
}


.heroSection{
    display: flex;
    justify-content: center;
    align-items: center;
}


.leftHeroSection {
    display: flex;
    justify-content: center;
    width: 50%;
    padding: 20px;
}


.leftMiniSection h1 {
    text-align: left;
    font-size: 40px
}

.leftMiniSection h2 {
    text-align: left;
    font-size: 20px;
}

.leftMiniSection{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 70%;
    text-align: left;

}

.rightHeroSection{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
}

.rightMiniSection{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.rightMiniSection img{
    width: 70%; 
    height: auto;
}

.orange-button {
    background-color:#FF914D;
    color:white;
    margin: 5px;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    text-align: center;
    font-size: 18px;
    width: 200px;
    text-align: center;
    min-height: 50px;
}

.green-button {
    background-color:#63BAAB;
    color:white;
    padding: 20px 30px;
    margin: 5px;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    text-align: center;
    font-size: 18px;
    width: 200px
}

.leftButtons a{
  text-decoration: none;
  color: white;
}

.circleContainer{
    position: fixed;
    width: 500px;
    left: 0;
    bottom: 88px;
    z-index: -1;
}

.circleDecor1{
    position: relative;
    left: 100px;
    bottom: -340px;
    height: 125px;
    width: 125px;
    background-image: linear-gradient(to right, #63BAAB, #00E9C1);
    border-radius: 75px;
    z-index: -2;
}

.circleDecor2{
    position: relative;
    left: -100px;
    bottom: 50px;
    height: 200px;
    width: 200px;
    background-image: linear-gradient(to right, #63BAAB, #00E9C1);
    border-radius: 100px;
    z-index: -2;
}

.circleDecor3{
    position: relative;
    left: 270px;
    bottom: 60px;
    height: 75px;
    width: 75px;
    background-image: linear-gradient(to right, #63BAAB, #00E9C1);
    border-radius: 50px;
    z-index: -2;
}

.processStepsMainConatiner{
    height: 300; 
    background-color:#fff; 
    width:100%; 
    z-index: 2;
}

.processStepsMainConatiner h3{
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    color: #63BAAB;
    margin-bottom: 2px;
}

.processStepsMainConatiner h4{
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    color: #FF914D;
    margin-bottom: 10px;
}

.processStepsContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction:row;
    padding: 20px;
}

.processIconContainer{
    display: flex;
    justify-content: space-evenly;
    width: 50%;
}

.processStep{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.processStep p{
    font-weight: 600;
    width: 70%;
}

.learnMoreContainer  {
    padding-bottom:30px; 
    padding-top: 10px; 
    background-color: #EDFFFC; 
    width:100%; 
    z-index: 2;
}

.learnMoreContainer h3{
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    color: #FF914D;
    margin-bottom: 10px;
}

.scroll-link {
    scroll-behavior: smooth;
  }

  .video-container {
    position: relative;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
  }
  
  iframe {
    width: 100%;
    height: 500px;
    display: block;
  }
  
 
  iframe::-webkit-media-controls {
    display: flex;
  }
  
  iframe::-webkit-media-controls-play-button,
  iframe::-webkit-media-controls-volume-slider {
    display: none; 
  }
  
  @media only screen and (max-width: 800px) {
    iframe {
      height: 400px; 
    }
  }
  
  @media only screen and (max-width: 650px) {
    iframe {
      height: 300px; 
    }
  }

@media(max-width: 750px){
    .heroSection{
        justify-content: center;
        align-content: center;
        flex-direction: column-reverse;
    }

    .circleContainer{
        display: none ;
    }

    .leftHeroSection{
        width: 80%;
    }
    .rightHeroSection{
        width: 80%;
    }

    .leftMiniSection h1 {
        text-align: center;
        font-size: 40px
    }
    
    .leftMiniSection h2 {
        text-align: center;
        font-size: 20px;
    }

    .leftMiniSection {
      text-align: center;
    }

    .processStepsContainer{
        display: grid;
        
    }
}

@media(max-width: 350px){
    .leftMiniSection h1 {
        text-align: center;
        font-size: 30px
    }
    
    .leftMiniSection h2 {
        text-align: center;
        font-size: 10px;
    }
}
