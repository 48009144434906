.mainBlogsContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mainBlogsContentContainer {
    display: flex;
    width: 85%;
    height: 100%;
}

.mainBlogsContainer a{
    text-decoration: none;
    color: black;
}

.blogPostMainContainer{
    text-align: left;
    width: 70%;
}

.blogPostContainer{
  border-bottom-style: solid;
  border-color: #FFB48D;
  padding-top: 10px;
  padding-bottom: 20px;
}

.blogPostContainer a:hover{
   color: #FF914D;
}

.blogPostContainer h2{
    font-size: 30px;
}

.blogPostContainer p{
    font-size: 20px;
}

.sidebarMainContainer{
    height: 500px;
}

.blogDescription a{
  color:#FF914D;
  font-weight: 500;
}

.blogDescription{
    font-size: 20px;
    width:100%;
    padding-top: 20px;
}



@media (max-width: 900px){
    .mainBlogsContentContainer{
        flex-direction: column;
        align-items: center;
        
    }

    .blogPostMainContainer{
        width: 90%;
        text-align: center;
    }
}

