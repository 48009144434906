.headerAlignment {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 20px;
    background-color:#EDFFFC;
 }
 
 .headerImageContainer{
   padding: 2px;
 }

 .headerImageContainer img{
    height: 45px;
 }
 
 
 .navContainer{
     display: flex;
     justify-content: flex-start;
     width: 50%;
 }
 
 .headerMenu {
     display: flex;
     justify-content: space-between;
     padding: 10px;
 }
 
 .headerMenu a{
     text-decoration: none;
     color: black;
     text-align: center;
     margin: 0 10px;
 }
 
 .headerMenu a:hover{
     color: #FF914D
 }

 .menuIcon{
    width: 30px;
    height: 5px;
    background-color: #F2FFFD;
 }

 .headerMenuMobile{
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 30px;
    padding: 7px;
    border-style: solid;
    border-color: #F2FFFD;
 }

 .headerMenuMobileSideBar{
    display: none;
    flex-direction: column;
    position: fixed;
    width: 200px;
    height: 100%;
    background-color: #fff;
    top: 0;
    bottom: 0;
    right: 0;
    border-style: solid;
    border-width: 0.5px;
 }
 .headerMenuMobileSideBar img{
    height: 45px;
 }

 .menuLinksMobile{
    height: 50%;
 }

 .menuLinksMobile nav{
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    height: 100%;
    padding: 10px;
    flex-direction: column;
 }

 .mobileLink{
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #000;
 }

 .closeIconContainer{
    display: flex;
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
 }
 
 
 @media(max-width: 700px){

    .headerAlignment{
        background-color:#63BAAB;
    }

    .headerMenuMobile{
        display: flex;
    }

    .navContainer{
        display: none;
    }

    .headerMenuMobileSideBar{
        display: flex;
    }
 }