.Container{
    height: 100%;
    width: 100%;
    background-color: #72DFCC ;
}

.mainContactContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 800px;
    width: 100%;
    background-image: url('../../assets/contactPageBackground.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.contactCard{
    display: flex;
    background-color: #EDFFFC;
    width: 80%;
    height: 90%;
    border-radius: 50px;
}

.inputMainContainer{
   display: flex;
   justify-content: center;
   align-items: center;
   width: 50%;
}

.inputSpacer{
    display: flex;
    flex-direction: column;
    width: 80%;
    height: 90%;
}

.inputSpacer button{
    margin-top: 50px;
    margin-left: 0px;
    height: 50px;
    border-radius: 30px;
}

.inputIntro{
   margin-top: 10px;
   text-align: left;
}

.inputIntro h2{
    font-size: 30px;
    color:#FF914D
}

.inputIntro h3{
    font-size: 20px;
    color:#72DFCC
}

.inputIntro p{
  width: 75%;
}

.inputsContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    margin-top: 20px;
}

.inputContainer{
    width: 100%;
}

.inputsContainer p{
    font-size: 12px;
}

.inputContainer input{
    width: 90%;
    height: 35px;
    margin-top: 5px;
}

.inputContainer textarea{
    width: 90%;
    height: 100px;
    resize: none;
}

.imageContainer{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.successMessageModal{
    position: absolute;
    top: 64%;
    transform: translate(0, -50%);
    width: 99%;
    height: 103%;
}

.successMessage{
    position: relative;
    top: 50%;
}


@media (max-width: 900px){
    
    .imageContainer{
        display: none;
    }

    .inputMainContainer{
        width: 100%;
    }

    .inputSpacer button{
        align-self: center;
    }
}

@media(max-width: 600px){
    .inputIntro h2{
       font-size:20px
    }  

    .inputIntro p{
       font-size: 14px;
    }
}