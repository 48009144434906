body{
  background-color: #EDFFFC
}

.App {
  text-align: center;
  width: 100%;
  height: 100%;
}

