
.websitesMainContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.websitesMainContainer h2{
    font-size: 30px;
    margin-bottom: 5px;
}

.websitesMainContainer p{
    font-size: 20px;
    width: 60%;
}


.websiteContainer{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  padding-bottom: 30px;
}

.websiteContainer h3{
    color: #63BAAB;
    font-size: 50px;
}

.websiteDivider{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 20px;
    width: 85%;
    background-color:  white;
    border-radius: 20px;
    box-shadow: 0 4px 2px -2px gray;
    margin-top: 50px;
}

.websiteImgConatainer{
    width: 48%;
    height: 400px;
    text-align: left;
}

.websiteImgConatainer img{
   height: 100%;
   width: 100%;
}

.websiteInfoContainer{
    display: flex;
    justify-content: space-evenly;
    text-align: left;
    flex-direction: column;
    padding: 10px;
    width: 40%;
    
}

.websiteInfoContainer h3{
    padding: 0;
    font-size: 30px;
    margin-bottom: 5px;
}

.websiteInfoContainer p{
    font-size: 22px;
    width: 100%;
}

.websiteContainer a{
    color:#FF914D;
   font-size: 25px;
}

@media(max-width: 650px){
    .websiteContainer h3{
        font-size: 40px;
        text-align: center;
    }
    .websiteDivider{
        flex-direction: column-reverse;
        width: 90%;
    }
    .websiteImgConatainer{
        width: 100%;
        height: 300px;
    }
    .websiteInfoContainer{
        width: 100%;
        height: 300px;
    }
    .websiteInfoContainer h3{
        font-size: 25px;
        margin-top: 5px;
    }
    .websiteInfoContainer p{
        font-size: 15px;
        text-align: center;
    }
    .websiteContainer a{
        font-size: 20px;
        text-align: center;
    }
}